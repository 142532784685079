import { Position } from '@xyflow/react';

import { QuestionNode } from './QuestionNode';
import { InitialNode } from './InitialNode';

export const nodeWidth = 400;
export const nodeHeight = 75;
const position = { x: 0, y: 0 };

export const initialNodes = [
  {
    id: 'start',
    type: 'initial',
    position: position,
    data: {
      question: {
        id: -1,
        text: 'Start Here'
      },
      firstQuestion: true
    },
    targetPosition: Position.Top,
    sourcePosition: Position.Bottom,
    deletable: false
  }
];

export const nodeTypes = {
  initial: InitialNode,
  'question-type': QuestionNode
  // Add any of your custom nodes here!
};
