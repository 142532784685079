import CustomEdge from './buttonEdge';
import { QuestionTypeEdge } from './QuestionTypeEdge';

export const initialEdges = [];

export const edgeTypes = {
  'question-edge': CustomEdge
  // 'question-edge': QuestionTypeEdge
  // Add your custom edge types here!
};

export const defaultEdgeOptions = {
  type: 'question-edge',
  interactionWidth: 10,
  hidden: false,
  deletable: true,
  selected: true,
  focusable: true
};
