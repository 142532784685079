import React, { useEffect, useState } from 'react';
import RedirectComp from './RedirectComp';
import UilSetting from '@iconscout/react-unicons/icons/uil-setting';
import { useSelector } from 'react-redux';
import { selectRoles } from 'store/slices/roles/rolesSlice';
import { isMobile } from 'react-device-detect';
import { getUserFromViewedUser } from 'services/utils/generic.methods';
import PopMenu from './popmenu';
import useViewport from 'services/viewport-hook';

const SideBarBottom = ({ user, showSideBar, switchTo, setShowSidebar }) => {
  const [showChat] = useState(false);

  const [loginDetails, setLoginDetails] = useState(null);

  const { roles } = useSelector(selectRoles);
  const screen = useViewport();

  useEffect(() => {
    let loggedInUser = getUserFromViewedUser();
    if (loggedInUser) {
      setLoginDetails(loggedInUser);
    }
  }, [showChat]);

  const [toggleTour] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (loginDetails && !loginDetails?.isLogginBefore) {
        setShowSidebar(true);
      } else {
      }
    }, 3000);
  }, [loginDetails]);

  useEffect(() => {
    if (toggleTour && isMobile) {
      document.body.className = 'body-wrapper';
    } else {
      document.body.className = '';
    }
  });

  return (
    <div
      className="sidebar-bottom-wrap"
      // style={{ width: '100%', left: !screen?.xs && '0' }}
      style={{ width: '100%', margin: '0 auto', left: '0', right: '0' }}
    >
      {/* <PreloadImages imgSource={dropdownEnums.enum_ImageSource} /> */}

      {switchTo.allowPortal || switchTo?.allowCRM ? (
        <RedirectComp showSideBar={showSideBar} switchTo={switchTo} />
      ) : null}
      {roles.settings.view && roles.company.view && (
        <PopMenu
          id={'introJS-item-setting'}
          showSideBar={showSideBar}
          title={'Settings'}
          path="/portal/settings"
          icon={<UilSetting size="24" className="sidebar-link" />}
          showTooltip={false}
          // clickHandler={e => history.push('/portal/settings/widget-editor')}
          clickHandler={e =>
            screen?.xs
              ? setShowSidebar(!showSideBar)
              : console.log('clickHandler')
          }
        />
      )}
    </div>
  );
};

export default SideBarBottom;
