import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Badge, Container } from 'react-bootstrap';
import {
  getAllCompanies,
  getAllCompaniesCount
} from 'store/slices/companies/companiesSlice';
import storage from 'services/utils/storage';
import { useDispatch, useSelector } from 'react-redux';
import '@inovua/reactdatagrid-community/index.css';
import { widgetCompaniesTableConfig } from 'services/utils/data-grid/tableConfig';
import { toast } from 'react-toastify';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { GridWrapper } from '../../../pub-components/GridWrapper';
import {
  getSelectedCompanies,
  setSelectedCompaniesToState
} from 'store/slices/widget-editor/widgetEditorSlice';
import { useTranslation } from 'react-i18next';
import useViewport from 'services/viewport-hook';
import { makeParamsFilters } from 'pub-components/GridWrapper/helper';
import { isJsonString, ToastTemplate } from 'services/utils/generic.methods';
import { useRouteMatch, useHistory } from 'react-router-dom';
import PageLoading from 'pub-components/page-loading';
export const dataKey = 'selected-companies-widgetSetting';

const useStyles = makeStyles(theme => ({
  companiesContainer: {
    // display: ({ isMobile }) => (isMobile ? 'none' : 'block !important')
  }
}));
const Companies = () => {
  const [selectedData, setSelectedData] = useState({});
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [refetch, setRefetch] = useState(false);
  const [loading, setLoading] = useState(false);
  const selectedCompanies = useSelector(getSelectedCompanies);
  const key = 'settings';
  const [vissibleColumns, setVissibleColumns] = useState(null);
  const screen = useViewport();
  const classes = useStyles({ isMobile: screen.xs });

  const { path, url } = useRouteMatch();
  const history = useHistory();
  // initialize selected Tab Data after Redirect From other screen
  useEffect(() => {
    let data = JSON.parse(storage.getItem(dataKey)) || [];
    setSelectedData(generateSelected(data));
    dispatch(setSelectedCompaniesToState(data));
  }, []);

  useEffect(() => {
    setSelectedData(generateSelected(selectedCompanies));
  }, [selectedCompanies]);

  // initialize selected Tab Data after Redirect From other screen
  useEffect(() => {
    let selected = JSON.parse(storage.getItem(dataKey));
    if (selected && selected.length) {
      viewDetail(selected[0]?.id);
    }
  }, []);

  // select Company
  const handleSelect = payload => {
    console.log('handleselect', payload, url);
    let selectedCompany = payload.data[0];
    updateCompaniesAndSaveToLS(payload.data);
    let companyId = selectedCompany?.id;
    viewDetail(companyId);
  };

  const viewDetail = companyId => {
    console.log(!storage.getItem('active-settings-menu'), '-------');
    // change route here route.push(`url/${companyId}/widget-editor`)

    const stored = isJsonString(storage.getItem('active-settings-menu'))
      ? JSON.parse(storage.getItem('active-settings-menu'))
      : '';

    const param = stored || 'widget-editor';
    history.push(`${url}/${companyId}/${param}`);
  };

  //  update Selected Companies and save to Local Storage
  const updateCompaniesAndSaveToLS = payload => {
    setSelectedData(payload);
    dispatch(setSelectedCompaniesToState(payload));
  };

  const generateSelected = arrayData => {
    return arrayData.reduce(
      (obj, item) => ({
        ...obj,
        [item['id']]: item
      }),
      {}
    );
  };

  useEffect(() => {
    const companiesCount = storage.getItem('companiesCount');
    if (!companiesCount) {
      let payload = {
        start: 0,
        limit: 5,
        filter: [],
        dir: 'ASC',
        sort: 'createdOn'
      };
      getAllCompanies(payload).then(data => {
        storage.setItem('companiesCount', data?.data?.data.length);
      });
    }
  }, []);

  const loadData = ({ skip, limit, sortInfo, filterValue }) => {
    try {
      setLoadingMessage('Loading ...');
      const updatedValue = makeParamsFilters(filterValue);
      let payload = {
        start: skip,
        limit: limit,
        filter: updatedValue,
        dir: !sortInfo ? 'DESC' : sortInfo.dir === 1 ? 'ASC' : 'DESC',
        sort: sortInfo ? sortInfo.name : 'createdOn'
      };
      return getAllCompanies(payload).then(response => {
        if (response.data.totalSize > 20) {
          setDataLimit(skip + response.data.data.length);
        } else {
          setDataLimit(response.data.data.length);
        }
        const totalCount = response.data.totalSize;

        setTotalRecords(totalCount);
        if (!JSON.parse(updatedValue).length) {
          if (totalCount === 1) {
            let singleCompany = response.data.data[0];
            updateCompaniesAndSaveToLS([singleCompany]);
            // if only one company available then view it directly
            viewDetail(singleCompany?.id);
          }
        }
        return { data: response.data.data, count: parseInt(totalCount) };
      });
    } catch (error) {
      toast.error(ToastTemplate.error(error || error.message));
    }
  };

  const dataSource = useCallback(loadData, []);
  const [dataLimit, setDataLimit] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState('Loading ...');

  const renderColumnContextMenu = useCallback((menuProps, { cellProps }) => {
    menuProps.items = menuProps.items.splice(0, 8);
  }, []);

  const handleColumnsChange = data => {
    setVissibleColumns({ ...data });
  };
  return (
    <>
      {loading && <PageLoading />}
      <Container fluid>
        <div className="bg-white border-radius mt-3">
          <div
            className={`pr-2 pl-2 ${classes.companiesContainer}`}
            hidden={loading}
          >
            <Row>
              <Col className="m-3">
                <h2 className="page-heading">Settings</h2>
              </Col>
            </Row>
            <Row>
              <Col className={`mx-3 pb-2 ${loading && 'blux-text'}`}>
                <div className="mobile_view">
                  <GridWrapper
                    refetch={refetch}
                    // checkboxOnlyRowSelect={true}
                    // checkboxColumn={true}
                    columns={widgetCompaniesTableConfig()}
                    totalRecords={totalRecords}
                    dataSource={dataSource}
                    setLoading={setLoading}
                    loadingMessage={loadingMessage}
                    renderColumnContextMenu={renderColumnContextMenu}
                    selectedData={selectedData}
                    handleSelect={handleSelect}
                    vissibleColumns={vissibleColumns}
                    storageKey={key}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
};

export default React.memo(Companies);
