export const ScriptsQuestionType = {
  None: 0,
  'Multiple Choice': 1,
  'True or False': 2,
  Text: 3,
  Time: 4,
  'Time/Calendar': 5,
  'Calendar/Date': 6,
  'Full Text': 8,
  'All That Apply': 7,
  'Appointment Scheduler': 9,
  Consent: 10
};
