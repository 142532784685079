export const AgentScriptActionType = {
  'Call Connect': 1,
  'Convert To SMS': 8,
  'Transfer To Live Agent': 7,
  'Mark Sales Lead': 4,
  'Mark Service Lead': 5,
  'Mark Other Lead': 6,
  'Send Lead': 2,
  'End Chat': 3
};
