import { AppMetaProvider } from 'pub-components/Sidebar/helper';
import config from 'config';
import {
  addSubdomainInURL,
  convertCustomerPortalLinkToLegacyPortal,
  getCookie,
  ToastTemplate
} from 'services/utils/generic.methods';
import storage from 'services/utils/storage';
import { toast } from 'react-toastify';

const SSO_Redirection = (arg = {}) => {
  const {
    subDomain = '',
    returnUrl = '',
    authToken = '',
    isLoginRedirect = false,
    isCompanyChangeRedirect = false,
    shouldOpenInSameWindow = false,
    profile
  } = arg;
  const user = profile || JSON.parse(storage.getItem('Profile'));
  let token = getCookie('Auth') || authToken;
  let _hostKey = JSON.parse(storage.getItem('AppMetaInfo'))?.host;

  if (!!token && !isCompanyChangeRedirect && !isLoginRedirect) {
    let host =
      (user && user.HostDomain) ||
      'https://' + AppMetaProvider().GetHostUrl(window.location.host);
    // Add subdomain "dashboard." before host url in case of Production/Beta Only
    if (config.environment === 'production' || config.environment === 'beta') {
      host =
        subDomain === 'dashboard.'
          ? addSubdomainInURL(
              (user && user.HostDomain) || config.portalURL,
              'dashboard'
            )
          : (user && user.HostDomain) || config.portalURL;
    }

    let redirectionWithToken =
      host +
      '/handlers/sso.ashx?' + // Slash before handlers is intentional in case if any host has no slash in host Domain
      'token=' +
      token +
      (returnUrl ? `&ReturnUrl=${returnUrl}` : '');
    // redirect to another link
    if (shouldOpenInSameWindow) {
      toast.success(ToastTemplate.success('Redirecting to the Portal...'));
      window.setTimeout(() => {
        window.location.assign(redirectionWithToken);
      }, 2000);
      // remove return url from localstorage here
      storage.deleteItem('SSO-return-location');
    } else {
      window.open(redirectionWithToken, `_newtab_${returnUrl}`);
    }
  } else if (isLoginRedirect) {
    let host =
      (user && user.HostDomain) ||
      'https://' + AppMetaProvider().GetHostUrl(window.location.host);
    let data = JSON.parse(storage.getItem('SSO-return-location'));
    let redirect = '';
    if (data?.search.indexOf('oldview=true') > -1) {
      let returnUrl = convertCustomerPortalLinkToLegacyPortal(data, 'id');
      redirect =
        host +
        '/Pages/Login.aspx?isNewPortalRedirect=true' +
        (_hostKey ? '&hostKey=' + _hostKey : '') +
        '&returnUrl=' +
        returnUrl;
    } else {
      redirect =
        host +
        '/Pages/Login.aspx?isNewPortalRedirect=true' +
        (_hostKey ? '&hostKey=' + _hostKey : '');
    }
    toast.success('Redirecting to the Single Sign On Authorization...', {
      toastId: 'redirect-sso-toast' // SSO-24 - persist multiple toast occurrence
    });
    window.setTimeout(() => {
      window.location.assign(redirect);
    }, 1000);
  } else if (isCompanyChangeRedirect && token) {
    let host =
      (user && user.HostDomain) ||
      'https://' + AppMetaProvider().GetHostUrl(window.location.host);
    let redirect =
      host +
      '/Pages/CompanySelector.aspx?isNewPortalRedirect=true&jwt=' +
      token +
      (_hostKey ? '&hostKey=' + _hostKey : '');
    toast.success('Redirecting to the Company Selector page...');
    window.setTimeout(() => {
      window.location.assign(redirect);
    }, 1000);
  }
};
export default SSO_Redirection;
