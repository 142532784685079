export const fr_widget = {
  // widget editor
  widget_heading: 'Éditeur de widgets',
  sidebar_item1: "Paramètres d'apparence",
  item1_child1: 'Éditeur de widgets',
  widget_companies: 'Entreprises',
  widget_save: 'Save',
  no_companies_msg:
    "Actuellement, aucune entreprise n'est ouverte, cliquez sur 'Rechercher des entreprises' pour modifier les paramètres",
  collapse_item1: 'Intégrer le widget',
  collapse_item1_tag:
    'Copiez ou installez le code du widget pour publier votre widget de chat',
  collapse_item2: 'Apparence',
  collapse_item2_tag:
    "Choisissez parmi une liste d'avatars de chat, de couleurs ou ajoutez les vôtres",
  customize_mobile_Check: 'Personnaliser les paramètres pour mobile',
  tab_desktop: 'Bureau',
  tab_mobile: 'Mobile',
  select_type: 'Sélectionnez le type de widget',
  theme_color: 'Couleur du thème',
  chat_avatar: 'Avatar de discussion',
  upload_image: 'Télécharger',
  enable_avatar_name: "Personnaliser le nom de l'avatar",
  avatar_name: 'Pseudonyme',
  avatar_name_tooltip:
    "Changer ce nom remplacera le titre de l'avatar existant.",
  chat_avatar_tooltip:
    'La sélection de plusieurs avatars entraînera une image différente à chaque fois que le widget de chat est chargé sur votre site Web',
  custom_images: 'Mes téléchargements',
  default: 'Images par défaut',
  widget_script: 'Script de widget',
  copy_snippet: "Copier l'extrait",
  widget_or: 'OU',
  easy_install: 'Installez facilement sur',
  wordpress_plugin: 'Wordpress via plugin',
  send_developer: 'Envoyer à votre développeur',
  designer_email: 'Courriel du concepteur',
  developer_emails: 'E-mail(s) du développeur',
  send_instruction: 'Envoyer les instructions',
  widget_error_info: 'Appuyez sur Entrée pour ajouter une adresse e-mail.'
};

export default fr_widget;
