import { createSlice } from '@reduxjs/toolkit';
import store from '../../../../../../store';
import { v4 as uuidv4 } from 'uuid';

const blankNode = {
  id: '0',
  type: 'question-type',
  position: { x: 0, y: 0 },
  data: {
    question: {
      id: '0',
      text: ''
    },
    firstQuestion: false
  }
};

const initialState = {
  node: blankNode
};

export const nodeSlice = createSlice({
  name: 'menu',
  initialState: initialState,
  reducers: {
    setNode: (state, action) => {
      state.node = action.payload;
    },
    updateText: (state, action) => {
      state.node.data.question.text = action.payload;
    },
    updateVisibility: (state, action) => {
      state.node.data.question.visibility = action.payload;
    },
    updateQuestionType: (state, action) => {
      state.node.data.question.questionType = action.payload;
    },
    updateNextQuestionId: (state, action) => {
      state.node.data.question.nextQuestionId = action.payload;
    },
    updateVisitorResponse: (state, action) => {
      state.node.data.question.visitorResponseType = action.payload;
    },
    updateVisitorResponses: (state, action) => {
      state.node.data.question.visitorResponseTypes = JSON.stringify(
        action.payload
      );
    },
    updateNextQuestionForOption: (state, action) => {
      // was this in typescript
      // state.node.data.question.agentScriptOptions!.find(option => option.id === action.payload.optionId)!.nextQuestionId = action.payload.questionId;
      const agentScriptOptions = state.node.data.question.agentScriptOptions;

      if (agentScriptOptions) {
        const option = agentScriptOptions.find(
          option => option.id === action.payload.optionId
        );

        if (option) {
          option.nextQuestionId = action.payload.questionId;
        }
      }
    },
    addOption: state => {
      const newId = uuidv4();
      const option = {
        id: newId,
        text: '',
        answerId: state.node.data.question.id,
        nextQuestionId: 0
      };
      const optionsList = state.node.data.question.agentScriptOptions ?? [];
      optionsList.push(option);
      state.node.data.question.agentScriptOptions = optionsList;
    },
    addTrueFalseOption: (state, action) => {
      const optionsList = state.node.data.question.agentScriptOptions ?? [];
      optionsList.push(action.payload.trueOption);
      optionsList.push(action.payload.falseOption);
      state.node.data.question.agentScriptOptions = optionsList;
    },
    updateOptionText: (state, action) => {
      // was this in typescript
      // state.node.data.question.agentScriptOptions!.find(option => option.id === action.payload.optionId)!.text = action.payload.text;
      const agentScriptOptions = state.node.data.question.agentScriptOptions;
      if (agentScriptOptions) {
        const option = agentScriptOptions.find(
          option => option.id === action.payload.optionId
        );
        if (option) {
          option.text = action.payload.text;
        }
      }
    },
    deleteOption: (state, action) => {
      const options = [];

      state.node.data.question.agentScriptOptions?.forEach(option => {
        if (option.id != action.payload) {
          options.push(option);
        }
      });
      state.node.data.question.agentScriptOptions = options;
    },
    clearOptions: state => {
      const options = [];
      state.node.data.question.agentScriptOptions = options;
    },
    clearNodeValues: state => {
      state.node.data.question = {
        text: '',
        questionType: 0
      };
    }
  }
});

export const {
  setNode,
  updateText,
  updateVisibility,
  updateQuestionType,
  updateNextQuestionId,
  updateVisitorResponse,
  updateVisitorResponses,
  updateNextQuestionForOption,
  updateOptionText,
  deleteOption,
  addOption,
  addTrueFalseOption,
  clearOptions,
  clearNodeValues
} = nodeSlice.actions;
export default nodeSlice.reducer;
