import React, { useEffect } from 'react';
import Image from 'react-bootstrap/Image';
import { AppMetaProvider } from './helper';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDynamicMeta,
  setDynamicMetaToState
} from 'store/slices/auth/authSlice';
import Fade from '@material-ui/core/Fade';
import { useState } from 'react';

const Logo = ({ login = false }) => {
  const dispatch = useDispatch();
  const _dynamicMeta = useSelector(selectDynamicMeta);
  const [_dynamicMetaLS, setDynamicMeta] = useState(null);
  let favicon = document.getElementById('favicon');
  let applyTouchIcon = document.getElementById('apple-touch-icon');

  useEffect(() => {
    if (!_dynamicMetaLS) {
      fetchAppMeta();
    }
  }, [_dynamicMetaLS]);

  useEffect(() => {
    if (!_dynamicMeta) {
      if (_dynamicMetaLS) {
        dispatch(setDynamicMetaToState(_dynamicMetaLS));
      }
    }
  }, [_dynamicMeta, _dynamicMetaLS]);

  var fetchAppMeta = async function () {
    let meta = await AppMetaProvider().GetDynamicMeta(false);
    setDynamicMeta(meta);
  };

  window.document.title = `Live Chat ${
    _dynamicMeta?.hostName ? '| ' + _dynamicMeta?.hostName : ''
  }`;
  applyTouchIcon.setAttribute('href', _dynamicMeta?.favIconLogo);
  favicon.setAttribute('href', _dynamicMeta?.favIconLogo);
  return (
    <div
      className="logo-container"
      style={{
        // width: login ? 'auto' : 'unset',
        maxWidth: login ? '170px' : 'unset',
        maxHeight: login ? 'unset' : '50px'
      }}
    >
      {!_dynamicMeta ? (
        login && <i className="uil uil-spinner spinner logo-resizing"></i>
      ) : _dynamicMeta.ssoEnabled && login ? (
        <i className="uil uil-spinner spinner logo-resizing"></i>
      ) : (
        <Fade in timeout={1000}>
          <Image
            className={'img-w  h-auto'}
            style={{ maxHeight: login ? '90px' : 'inherit' }}
            src={
              _dynamicMeta.isDirectHost || _dynamicMeta.isReferrerHost
                ? _dynamicMeta?.emailLogo
                : _dynamicMeta?.hostLogo
            }
          />
        </Fade>
      )}
    </div>
  );
};

export default Logo;
