import ApiServices from 'api/apexchat.api.services';
import { AnalyticAPI, general } from 'api/endpoints';
import { createSlice } from '@reduxjs/toolkit';

export const getActivationStats = payload => {
  let analyticAPI = AnalyticAPI.getActivationStats;
  return ApiServices.post(analyticAPI, payload);
};

export const getLeadgeneratorStats = payload => {
  let analyticAPI = `${AnalyticAPI.getLeadgeneratorStats}?pageSize=${payload.pageSize}`;
  return ApiServices.post(analyticAPI, {});
};

export const getTopBottomBillersStats = payload => {
  let analyticAPI = `${AnalyticAPI.getTopBottomBillersStats}?pageSize=${payload.pageSize}`;
  return ApiServices.post(analyticAPI, {});
};

// export const getQuickStats = payload => {
//   let analyticAPI = AnalyticAPI.getQuickStats;
//   return ApiServices.getWithParams(analyticAPI, { params: payload });
// };

export const getQuickStats = payload => {
  let analyticAPI = AnalyticAPI.getQuickStats;
  return ApiServices.post(analyticAPI, payload);
};
export const getFilters = payload => {
  let analyticAPI = AnalyticAPI.getFilters;
  return ApiServices.getWithParams(analyticAPI, { params: payload });
};
export const getChartsAndGraphs = payload => {
  let analyticAPI = AnalyticAPI.getChartsAndGraphs;
  return ApiServices.post(analyticAPI, payload);
};

export const getChatReferralsStats = payload => {
  // TODO: replace the api with actual one
  // let analyticAPI = AnalyticAPI.getActivationStats;
  // return ApiServices.post(analyticAPI, payload);
  return true;
};

export const getFeaturesUsageStats = payload => {
  // TODO: replace the api with actual one
  // let analyticAPI = AnalyticAPI.getActivationStats;
  // return ApiServices.post(analyticAPI, payload);
  return true;
};

export const getTopReveneueStats = payload => {
  let analyticAPI = `${AnalyticAPI.getTopRevenueStats}?pageSize=${payload.pageSize}`;
  return ApiServices.post(analyticAPI, {});
};

export const getTopInactiveBillersStats = payload => {
  let analyticAPI = `${AnalyticAPI.getTopInactiveBillerStats}?pageSize=${payload.pageSize}`;
  return ApiServices.post(analyticAPI, {});
};

export const getBudgetPacingStats = payload => {
  let analyticAPI = `${AnalyticAPI.getBudgetPacingStats}?pageSize=${payload.pageSize}`;
  return ApiServices.post(analyticAPI, {});
};

export const getAgentImagesCTRStats = payload => {
  let analyticAPI = `${AnalyticAPI.getAgentImagesCTRStats}?pageSize=${payload.pageSize}`;
  return ApiServices.post(analyticAPI, {});
};

export const getAccountsStats = payload => {
  let analyticAPI = `${AnalyticAPI.getAccountsStats}?pageSize=${payload.pageSize}`;
  return ApiServices.post(analyticAPI, {});
};

export const getBusinessTypes = payload => {
  let analyticAPI = general.getBusinessCategories;
  return ApiServices.getWithParams(analyticAPI, {});
};

export const getBusinessSubCategories = payload => {
  let analyticAPI = general.getBusinessSubCategories;
  return ApiServices.getWithParams(analyticAPI, { params: payload });
};
// Redux Slice

const initialState = {
  clearAll: false,
  allowedFilters: {}
};

export const flitersSlice = createSlice({
  name: 'filters',
  initialState: { ...initialState },

  reducers: {
    /**
     * @name @login
     * @description login reducer to call login async API with axios instance
     * @requires ApiServices.get method, state and API payload
     **/

    // startLoading: (state = initialState, action) => {
    //   //  add random number to loading
    //   let randomeNumber = Math.floor(Math.random() * (80 - 20) + 20);
    //   if (state.loading + randomeNumber < 90)
    //     state.loading = state.loading + randomeNumber;
    //   else state.loading = randomeNumber;
    // },

    clearAll: (state, action) => {
      state.clearAll = action.payload;
    },
    setAllowedFilters: (state, action) => {
      state.allowedFilters = action.payload;
    }

    // showLoading: (state = initialState, action) => {
    //   state.show = action.payload;
    //   if (!action.payload) {
    //     state.loading = initialState.loading;
    //   }
    // }
  }
});

export const { clearAll, setAllowedFilters } = flitersSlice.actions;

/* called a selector and allows to select values from the state */
export const isClearAll = state => {
  return state.filters.clearAll;
};
export const getAllowedFilters = state => {
  return state.filters.allowedFilters;
};

export default flitersSlice.reducer;