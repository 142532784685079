import { Button, Paper, TextField } from '@material-ui/core';
import DropDownEditable from 'pub-components/ui-controls/DropDownEditable';
import { ScriptsQuestionType } from '../enums';
import { Add, Close } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import {
  addNewEdges,
  addNode,
  deleteEdge,
  updateNextQuestionId
} from '../flow/flow-slice';
import { v4 as uuidv4 } from 'uuid';
import { Position } from '@xyflow/react';
import { useState } from 'react';

function NodeMenu({
  visible,
  eventChangeVisibility,
  source,
  target,
  sourceX,
  sourceY
}) {
  const dispatch = useDispatch();

  const [message, setMessageText] = useState();
  const [questionType, setQuestionType] = useState(0);

  const onMessageTextChange = event => {
    setMessageText(event.target.value);
  };

  const onQuestionTypeChange = event => {
    setQuestionType(event.target.value);
  };

  const createNodeInMiddle = () => {
    eventChangeVisibility();

    const oldEdge = {
      id: `${source}->${target}`,
      type: 'question-edge',
      source: source,
      target: target
    };
    dispatch(deleteEdge(oldEdge));

    const newId = uuidv4();

    const newNode = {
      id: newId,
      type: 'question-type',
      targetPosition: Position.Top,
      sourcePosition: Position.Bottom,
      position: {
        x: sourceX,
        y: sourceY
      },
      data: {
        question: {
          id: 0,
          text: message,
          questionType: questionType,
          nextQuestionId: target
        }
      }
    };
    dispatch(updateNextQuestionId(source, newId));
    dispatch(addNode(newNode));

    const newEdge1 = {
      id: `${source}->${newId}`,
      type: 'question-edge',
      source: source,
      target: newId
    };
    const newEdge2 = {
      id: `${newId}->${target}`,
      type: 'question-edge',
      source: newId,
      target: target
    };
    dispatch(addNewEdges([newEdge1, newEdge2]));
  };

  const getQTOptions = () => {
    return Object.keys(ScriptsQuestionType)
      .filter(v => isNaN(Number(v)))
      .map(value => ({ value: ScriptsQuestionType[value], name: value }));
  };

  return (
    <>
      <Paper
        style={{
          backgroundColor: '#fff',
          borderRadius: '5px',
          width: 250,
          display: visible ? 'flex' : 'none',
          flexDirection: 'column',
          fontSize: '0.7rem',
          position: 'absolute',
          left: '50px',
          bottom: '-95px'
        }}
        elevation={3}
      >
        {' '}
        <div
          className="node-heading"
          style={{
            padding: '8px',
            borderRadius: '5px',
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: '0.8rem'
          }}
        >
          <div>Add New Question</div>
          <div style={{ cursor: 'pointer' }} onClick={eventChangeVisibility}>
            <Close fontSize="small" />
          </div>
        </div>
        <div
          style={{
            padding: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px'
          }}
        >
          <TextField
            label="Message"
            variant="outlined"
            size="small"
            onChange={onMessageTextChange}
          />
          <DropDownEditable
            heading="Question Type"
            field="questionType"
            editable={true}
            materialFields
            options={getQTOptions()}
            size="small"
            handleChange={onQuestionTypeChange}
          />

          <Button
            variant="contained"
            size="small"
            style={{
              background: 'var(--primary)',
              color: '#fff'
            }}
            onClick={createNodeInMiddle}
          >
            <Add />
          </Button>
        </div>
      </Paper>
    </>
  );
}

export default NodeMenu;
