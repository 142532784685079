import { useState } from 'react';
import { Handle, Position } from '@xyflow/react';

import { PlusCircleFill } from 'react-bootstrap-icons';
import { v4 as uuidv4 } from 'uuid';

import {
  addNode,
  addNewEdge,
  deleteNode,
  updateNextQuestionId
} from '../flow/flow-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { nodeHeight } from '.';
import { setNode } from '../menu/menu-slice';
import { show } from '../overlay/overlay-slice';
import { ScriptsQuestionType } from '../enums';
import { Delete, Edit } from '@material-ui/icons';

const hasOptions = questionType => {
  return questionType === 1 || questionType === 2 || questionType === 7;
};

export function QuestionNode(props) {
  const flow = useAppSelector(state => state.flow);
  const dispatch = useAppDispatch();

  const menuNode = flow.nodes.find(node => node.id === props.id);

  const [hover, setHoverState] = useState(false);

  const openMenu = () => {
    dispatch(setNode(menuNode));
    dispatch(show());
  };

  const handleDelete = () => {
    dispatch(deleteNode(menuNode));
  };

  const handleClick = e => {
    e.preventDefault();
    setHoverState(false);

    const newId = uuidv4();

    const newNode = {
      id: newId,
      type: 'question-type',
      targetPosition: Position.Top,
      sourcePosition: Position.Bottom,
      position: {
        x: props.positionAbsoluteX,
        y: props.positionAbsoluteY + nodeHeight * 1.5
      },
      data: {
        question: {
          id: 0,
          text: 'New Question',
          questionType: 0,
          nextQuestionId: 0
        }
      }
    };
    dispatch(updateNextQuestionId(props.id, newId));
    dispatch(addNode(newNode));

    const newEdge = {
      id: `${props.id}->${newId}`,
      type: 'question-edge',
      source: props.id,
      target: newId
    };
    dispatch(addNewEdge(newEdge));
  };

  const qtWithOptions = hasOptions(props.data.question.questionType);

  const hideSourceHandle =
    (qtWithOptions && props.data.question.agentScriptOptions?.length === 0) ||
    (!qtWithOptions &&
      (props.data.question.nextQuestionId === undefined ||
        props.data.question.nextQuestionId === 0));

  return (
    <>
      <div
        className="question-type-node"
        onMouseEnter={() => setHoverState(true)}
        onMouseLeave={() => setHoverState(false)}
      >
        {!props.data.firstQuestion && (
          <Handle type="target" position={props?.targetPosition} />
        )}
        <div className="node-container">
          <div className="node-heading">
            <div>
              {Object.keys(ScriptsQuestionType)
                .filter(v => isNaN(Number(v)))
                .find(
                  value =>
                    ScriptsQuestionType[value] ===
                    props.data.question.questionType
                )}
            </div>
            <div style={{ display: 'flex', gap: '2px' }}>
              <div onClick={openMenu} style={{ cursor: 'pointer' }}>
                <Edit fontSize="small" />
              </div>
              <div onClick={handleDelete} style={{ cursor: 'pointer' }}>
                <Delete fontSize="small" />
              </div>
            </div>
          </div>
          <div style={{ padding: '0px 5px' }}>
            {props.data.question.text && (
              <div
                className="question-type-node-inner"
                dangerouslySetInnerHTML={{
                  __html: props.data.question.text
                }}
              ></div>
            )}
          </div>
        </div>
        <Handle
          type="source"
          position={props?.sourcePosition}
          style={{
            visibility: hideSourceHandle ? 'hidden' : 'visible',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          isConnectable={!qtWithOptions}
        >
          {flow.edges.findIndex(edge => edge.source === props.id) === -1 && (
            <button
              className={'edgebutton'}
              style={{
                visibility: qtWithOptions
                  ? 'hidden'
                  : hover
                  ? 'visible'
                  : 'hidden',
                position: 'relative',
                top: hideSourceHandle ? 'unset' : '12px'
              }}
              onClick={handleClick}
            >
              +
            </button>
          )}
        </Handle>
      </div>
    </>
  );
}
