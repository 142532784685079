import React, { useState, useEffect } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const DropDownEditable = ({
  heading,
  field,
  dataProperty,
  titleText,
  error,
  errorMessage,
  helperText,
  editable,
  readOnly,
  handleChange,
  options = [],
  styles = {},
  fullWidth = true,
  size
}) => {
  const [valueDropDown, setValueDropDown] = useState(
    dataProperty ? dataProperty.toString() : ''
  );

  const handleOnChange = event => {
    setValueDropDown(event.target.value);
    handleChange(event, field, event.target.value);
  };

  useEffect(() => {
    setValueDropDown(dataProperty ? dataProperty.toString() : '');
  }, [dataProperty]);

  return (
    <FormControl
      fullWidth={fullWidth}
      variant="outlined"
      className={`form-group ${editable ? '' : 'has-disabled'} ${
        error ? 'is-invalid' : ''
      }`}
      style={styles}
      size={size}
    >
      <InputLabel htmlFor={field} className={error ? 'text-danger' : ''}>
        {heading}
      </InputLabel>
      <Select
        value={valueDropDown}
        onChange={handleOnChange}
        disabled={!editable}
        label={heading}
        id={field}
        name={field}
        error={!!error}
      >
        {options.map(value => (
          <MenuItem
            key={value.value}
            value={isNaN(value.value) ? value.value : Number(value.value)}
            title={value.description}
          >
            {value.name}
          </MenuItem>
        ))}
      </Select>
      {/* {helperText && (
        <Tooltip title={helperText} placement="top">
          <InfoIcon className="list-icon-after-text ml-1" />
        </Tooltip>
      )} */}
      {error && (
        <FormHelperText
          style={{
            color: 'red',
            position: 'absolute',
            bottom: '-22px'
          }}
        >
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default DropDownEditable;
