import { createSlice } from '@reduxjs/toolkit';
import ApiServices from 'api/apexchat.api.services';
import { UserAPI } from 'api/endpoints';
import {
  isObjectEmpty,
  tryParseJson,
  updateViewedUser
} from 'services/utils/generic.methods';
import storage from 'services/utils/storage';

/**
 * @name @createSlice
 * @description create redux store slice for userPreferenceSettings
 **/
export const userPreferenceSettings = createSlice({
  name: 'userPreferenceSettings',
  initialState: {
    preferences: {
      version: 0,
      analytics: {},
      voiceAnalytics: {},
      general: {
        tour: false,
        language: 'en-US'
      },
      messaging: {
        enableAutoPickup: false,
        enableNotificationSound: true,
        signUpRequested: false
      },
      companies: {
        filters: {},
        columns: null
      },
      chats: {
        filters: {},
        columns: null
      },
      leads: {
        filters: {},
        columns: null
      },
      reports: {
        filters: {},
        columns: null
      },
      queue: {
        filters: {},
        columns: null
      },
      provision: {
        filters: {},
        columns: null
      },
      settings: {
        filters: {},
        columns: null
      },
      userinvite: {
        filters: {},
        columns: null
      },
      operations: {
        filters: {},
        columns: null
      }
    }
  },

  reducers: {
    setGridFilters: (state, action) => {
      state.preferences[action.payload.key].filters = {
        ...action.payload.filters
      };
    },

    setGridColumns: (state, action) => {
      state.preferences.version += 1;
      state.preferences[action.payload.key].columns = action.payload.columns;
    },

    setEnableAutoPickup: (state, action) => {
      state.preferences.version += 1;
      state.preferences.messaging.enableAutoPickup = !!action.payload;
      updateViewedUser({ ap: !!action.payload });
    },

    setEnableNotificationSound: (state, action) => {
      state.preferences.version += 1;
      state.preferences.messaging.enableNotificationSound = !!action.payload;
      updateViewedUser({ enableSound: !!action.payload });
    },

    setAnalyticsFilters: (state, action) => {
      state.preferences.version += 1;
      const key = action.payload?.key || 'analytics';
      const filters = action.payload?.filters || action.payload;
      state.preferences[key].filters = { ...filters };
      storage.setItem(`${key}-selected-filters`, filters);
    },

    setAnalyticsCharts: (state, action) => {
      state.preferences.version += 1;
      const key = action.payload?.key || 'analytics';
      const charts = action.payload?.charts || action.payload;
      state.preferences[key].charts = { ...charts };
      storage.setItem(`${key}ChartsHideShow`, charts);
    },

    setAnalyticsDuration: (state, action) => {
      state.preferences.version += 1;
      const key = action.payload?.duration ? action.payload.key : 'analytics';
      const duration = action.payload?.duration || action.payload;
      state.preferences[key].duration = { ...duration };
      storage.setItem(`${key !== 'analytics' ? key : ''}duration`, duration);
    },

    setAnalyticsWidgets: (state, action) => {
      state.preferences.version += 1;
      const key = action.payload?.key || 'analytics';
      const widgets = action.payload?.widgets || action.payload;
      state.preferences[key].widgets = [...widgets];
      storage.setItem(`${key}-quickstats-widget`, widgets);
    },

    setLanguage: (state, action) => {
      let lang = action.payload;
      if (state.preferences.general.language !== lang) {
        state.preferences.version += 1;
        state.preferences.general.language = lang;
        storage.setItem('language', lang);
      }
    },

    setSignupRequested: (state, action) => {
      state.preferences.version += 1;
      state.preferences.messaging.signUpRequested = !!action.payload;
    },

    setPreferencesToState: (state, action) => {
      let newPreferences = { ...action.payload };
      // Set items to local storage
      const { widgets, filters, charts, duration } =
        newPreferences.analytics || {};
      const {
        widgets: voiceWidgets,
        filters: voiceFilters,
        charts: voiceCharts,
        duration: voiceDuration
      } = newPreferences.voiceAnalytics || {};
      const { language, tour } = newPreferences.general || {};
      const { enableAutoPickup, enableNotificationSound } =
        newPreferences.messaging || {};
      updateViewedUser({
        ap: !!enableAutoPickup,
        enableSound: !!enableNotificationSound
      });

      // Remove filters key
      Object.keys(newPreferences).forEach(categoryKey => {
        const { filters, ...rest } = newPreferences[categoryKey];
        newPreferences[categoryKey] = rest;
      });

      if (voiceWidgets && voiceWidgets.length)
        storage.setItem('voiceAnalytics-quickstats-widget', voiceWidgets);
      if (voiceCharts && !isObjectEmpty(voiceCharts))
        storage.setItem('voiceAnlayticsChartsHideShow', voiceCharts);
      if (voiceFilters && !isObjectEmpty(voiceFilters))
        storage.setItem('voiceAnlaytics-selected-filters', voiceFilters);
      if (voiceDuration && !isObjectEmpty(voiceDuration))
        storage.setItem('voiceAnlayticsduration', voiceDuration);

      if (widgets && widgets.length)
        storage.setItem('analytics-quickstats-widget', widgets);
      if (charts && !isObjectEmpty(charts))
        storage.setItem('analyticsChartsHideShow', charts);
      if (filters && !isObjectEmpty(filters))
        storage.setItem('analytics-selected-filters', filters);
      if (duration && !isObjectEmpty(duration))
        storage.setItem('duration', duration);
      if (language && language !== '') storage.setItem('language', language);

      return {
        preferences: {
          ...state.preferences,
          ...newPreferences,
          version: 0
        }
      };
    }
  }
});

export const {
  setGridFilters,
  setGridColumns,
  setAnalyticsFilters,
  setAnalyticsCharts,
  setAnalyticsDuration,
  setAnalyticsWidgets,
  setLanguage,
  setSignupRequested,
  setEnableAutoPickup,
  setEnableNotificationSound,
  setPreferencesToState
} = userPreferenceSettings.actions;

export const selectLanguage = state => {
  return state.userPreferenceSettings.preferences.general.language;
};

export const getSignupRequested = state => {
  return state.userPreferenceSettings.preferences.messaging.signUpRequested;
};

export const getUserPreferenceVersion = state => {
  return state.userPreferenceSettings.preferences.version;
};

export const getUserPreferences = state => {
  return state.userPreferenceSettings.preferences;
};

export const selectGridFilters = (state, key) => {
  if (key) return state.userPreferenceSettings.preferences[key].filters;
  else return null;
};

export const selectGridColumns = (state, key) => {
  return state.userPreferenceSettings.preferences[key].columns;
};

export const getAutoPickupEnable = state => {
  return state.userPreferenceSettings.preferences.messaging.enableAutoPickup;
};

export const getEnableNotificationSound = state => {
  return state.userPreferenceSettings.preferences.messaging
    .enableNotificationSound;
};

export const getUserPreferenceSettings = payload => dispatch => {
  let userAPI = UserAPI.preferenceSettings(payload.params.id);
  return ApiServices.getWithParams(userAPI, payload)
    .then(async res => {
      let { data } = res;
      if (data?.success) {
        const preferences = tryParseJson(data.data);
        if (preferences) await dispatch(setPreferencesToState(preferences));
        return Promise.resolve(data);
      }
      return Promise.reject(res);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const updateUserPreferences = payload => {
  let userAPI = UserAPI.preferenceSettings(payload.userId);
  return ApiServices.put(userAPI, payload);
};

export default userPreferenceSettings.reducer;
