import * as React from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Calendar, DateRangePicker, DefinedRange } from 'react-date-range';
import { UilCalendarAlt } from '@iconscout/react-unicons';
import './style.scss';
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  startOfYear,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  format,
  setYear,
  setMonth
} from 'date-fns';
import { defaultStaticRanges } from 'react-date-range'; // this will give you already defined list
import { createStaticRanges } from 'react-date-range'; //pass your object to this method
import InputWithStartIcon from 'pub-components/InputWithStartIcon';
import UilCalender from '@iconscout/react-unicons/icons/uil-calender';
import UilTimes from '@iconscout/react-unicons/icons/uil-times';
import { Button } from 'react-bootstrap';
import { useEffect } from 'react';
import { ArrowDropDown } from '@material-ui/icons';

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfYear: startOfYear(new Date()),
  beforeYear: setYear(new Date(), 2007),
  beforeSixMonths: setMonth(new Date(), new Date().getMonth() - 6)
};

const InitalSelectionRange = {
  startDate: null,
  endDate: new Date(),
  key: 'selection'
};

const DateRangeDropdown = ({
  onDateChange,
  id = '',
  useButton = false,
  placement = '',
  initialRange = null,
  showValue = false
}) => {
  const [show, setShow] = React.useState(false);
  const [inputValue, setInputValue] = React.useState(showValue);
  const [selectedRange, setSelectedRange] = React.useState('This Month');
  const [dateRange, setDateRange] = React.useState(
    initialRange ?? InitalSelectionRange
  );
  const [focusedRange, setFocusedRange] = React.useState([0, 0]);

  const dateRef = React.useRef(null);
  const divRef = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  let customOptions = [
    {
      // this is your predefined date range object
      label: 'Month to date',
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: dateRange?.endDate || defineds.startOfToday
      })
    },
    {
      // this is your predefined date range object
      label: 'Dates before',
      range: () => ({
        startDate: defineds.beforeSixMonths,
        endDate: dateRange?.startDate || defineds.startOfToday
      })
    },
    {
      // this is your predefined date range object
      label: 'Dates after',
      range: () => ({
        startDate: dateRange?.endDate || defineds.startOfToday,
        endDate: defineds.startOfToday
      })
    }
  ];

  let customDateRange = createStaticRanges(customOptions);

  React.useEffect(() => {
    /**
     * close menu if clicked on outside of element
     */
    function handleClickOutside(event) {
      let elem = document.getElementById(`date-range-scroll-div-${id}`);
      let input = document.getElementById(`date-range-${id}`);
      let icon = document.getElementById(`calendar-icon-${id}`);
      if (
        show &&
        !elem.contains(event.target) &&
        !input.contains(event.target) &&
        !icon?.contains(event.target)
      ) {
        setShow(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show]);

  useEffect(() => {
    const selectedStaticRange = [
      ...defaultStaticRanges,
      ...customDateRange
    ].find(x => x.isSelected(dateRange));
    if (selectedStaticRange) {
      setSelectedRange(selectedStaticRange.label);
    } else {
      setSelectedRange('Custom');
    }
  }, [dateRange]);

  const openCalendar = () => {
    setShow(!show);
  };

  const openCalendarOnFocus = e => {
    openPopper(e.target);
  };

  const handleRangeSelect = ranges => {
    setDateRange(ranges.selection);
    // setFocusedRange([0, 1]);

    if (dateRef.current !== null) {
      // dateRef.current.updateShownDate = () => {};
      setTimeout(() => {
        console.log('dateRef--', dateRef.current);
        // dateRef.current.dateRange.calendar.updateShownDate = () => {};
        // dateRef.current.dateRange.calendar.focusToDate(
        //   ranges?.selection?.endDate
        // );
      }, 1000);
    }

    setTimeout(() => {
      changePopperPlacement();
    }, 100);
  };

  const applyChanges = () => {
    if (dateRange?.startDate) {
      onDateChange(dateRange);
      openCalendar(false);
      setInputValue(true);
    }
  };

  const getDateValue = (dateFormat = 'dd/MM/yyyy', seperator = 'to ') => {
    if (dateRange?.startDate) {
      let startDate = format(new Date(dateRange?.startDate), dateFormat);
      let endDate = format(new Date(dateRange?.endDate), dateFormat);
      let value = `${startDate} ${
        endDate && startDate !== endDate ? seperator + endDate : ''
      }`;
      return `${value}`;
    }
  };

  const clearInput = () => {
    setDateRange(InitalSelectionRange);
    setInputValue(false);
    let emptyDateRange = { startDate: '', endDate: '' };
    let inputElem = document.getElementById(`date-range-${id}`);
    inputElem.value = '';
    // reset grid filter state and refetch grid data
    onDateChange(emptyDateRange);
  };

  // set fr (focusedRange) in state
  const onRangeChange = fr => {
    setFocusedRange(fr);
  };

  const openPopper = e => {
    if (show) {
      setShow(false);
      changePopperPlacement(true);
    } else {
      setAnchorEl(e);
      setShow(true);
      changePopperPlacement();
    }
  };

  const changePopperPlacement = (reset = false) => {
    // let popperElem = document.getElementById('popper-element');
    // let dateRangeElem = document.getElementsByClassName(
    //   'date-dropdown-wrapper'
    // )[0];
    let dateRangeElem = document.getElementById('date-range-scroll-div-' + id);
    console.log('isinview', isInViewport(dateRangeElem));
    if (dateRangeElem && !isInViewport(dateRangeElem)) {
      // add left -390px to view popper completely
      console.log('dateRangeElem', dateRangeElem.style);
      if (placement == 'right') dateRangeElem.style.right = '0px';
      else dateRangeElem.style.left = '-234px';
    }
    if (dateRangeElem && reset) {
      if (placement == 'right') dateRangeElem.style.right = '0px';
      else dateRangeElem.style.left = '0px';
    }
  };

  const onIconClick = e => {
    e.stopPropagation();
    let inputElem = document.getElementById(`date-range-${id}`);
    openPopper(inputElem);
  };

  return (
    <div className="date-range-container">
      {!useButton ? (
        <InputWithStartIcon
          startTextOrIcon={null}
          endTextOrIcon={
            <>
              {inputValue && <UilTimes onClick={clearInput} />}
              <span
                onClick={onIconClick}
                className="d-inline-block"
                id={`calendar-icon-${id}`}
              >
                <UilCalender />
              </span>
            </>
          }
          name="date-range"
          label="date-range"
          placeholder="Select date"
          handleOnChange={() => console.log('input change')}
          value={getDateValue()}
          readOnly={true}
          onClick={openCalendarOnFocus}
          id={`date-range-${id}`}
        />
      ) : (
        <Button
          id={`date-range-${id}`}
          name="date-range"
          className="dropdown-btn d-flex align-items-center justify-content-center"
          variant={'primary'}
          onClick={onIconClick}
          style={{ marginLeft: '15px' }}
        >
          <UilCalender size={18} className="mr-2" />
          {getDateValue('MMM, dd yyyy', '- ')}
        </Button>
      )}
      {/* <Popper
        id="popper-element"
        open={show}
        anchorEl={anchorEl}
        transition
        placement={'bottom-start'}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <div className="date-range-wrapper">
                <DateRangePicker
                  ref={dateRef}
                  className={'date-dropdown-wrapper'}
                  id={'date-range-filter'}
                  ranges={[dateRange]}
                  onChange={handleRangeSelect}
                  staticRanges={[...defaultStaticRanges, ...customDateRange]}
                  maxDate={defineds.startOfToday}
                  onRangeFocusChange={onRangeChange}
                  focusedRange={focusedRange}
                  initialFocusedRange={focusedRange}
                  preventSnapRefocus={true}
                  showSelectionPreview={true}
                  direction="horizontal"
                />
                <div className="action-buttons">
                  <Button
                    className="btn-md detail-button ml-3"
                    type="button"
                    variant="primary"
                    onClick={applyChanges}
                  >
                    Apply
                  </Button>
                  <Button
                    className="btn-md detail-button ml-3"
                    type="button"
                    variant="outline-primary"
                    onClick={openCalendar}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Paper>
          </Fade>
        )}
      </Popper> */}
      {true && (
        // <ScrollBar
        //   id="date-range-scroll-div"
        //   horizontalScroll={false}
        //   style={{
        //     minHeight: '80px',
        //     minWidth: '580px',
        //     height: 'calc(100vh - 300px)'
        //   }}
        // >
        <div
          className="date-range-wrapper"
          id={`date-range-scroll-div-${id}`}
          ref={divRef}
          style={{
            visibility: show ? 'visible' : 'hidden'
          }}
        >
          {/* Range selection */}
          <DateRangePicker
            ref={dateRef}
            className={'date-dropdown-wrapper'}
            id={`date-range-filter-${id}`}
            ranges={[dateRange]}
            onChange={handleRangeSelect}
            staticRanges={[...defaultStaticRanges, ...customDateRange]}
            inputRanges={[]}
            maxDate={defineds.startOfToday}
            minDate={defineds.beforeSixMonths}
            startDatePlaceholder={'Start Date'}
            endDatePlaceholder={'End Date'}
            onRangeFocusChange={onRangeChange}
            focusedRange={focusedRange}
            initialFocusedRange={focusedRange}
            preventSnapRefocus={true}
            showSelectionPreview={true}
            direction="horizontal"
            rangeColors={['var(--primary)']}
          />
          <div className="action-buttons">
            <Button
              className="btn-md detail-button ml-3"
              type="button"
              variant="outline-primary"
              onClick={openCalendar}
            >
              Cancel
            </Button>
            <Button
              className="btn-md detail-button ml-3"
              type="button"
              variant="primary"
              onClick={applyChanges}
            >
              Apply
            </Button>
          </div>
        </div>
        // {/* </ScrollBar> */}
      )}
    </div>
  );
};

export default DateRangeDropdown;

const isInViewport = el => {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    // rect.bottom <=
    //   (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right + 5 <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
};
