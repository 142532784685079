import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BlazeoDataPromise } from '../integration';

const position = { x: 0, y: 0 };

const hasOptions = questionType => {
  return questionType === 1 || questionType === 2 || questionType === 7;
};

const hasNextQuestion = node => {
  let hasNextQuestion = false;
  if (hasOptions(node.questionType)) {
    node.agentScriptOptions.forEach(option => {
      if (option.nextQuestionId > 0) {
        hasNextQuestion = true;
      }
    });
  } else {
    if (node.nextQuestionId > 0) {
      hasNextQuestion = true;
    }
  }
  return hasNextQuestion;
};

const getNextNodes = (allQuestions, usedQuestions, nodeId) => {
  // Get Node using id
  const node = allQuestions.find(question => question.id === nodeId);

  // Error state (didn't find this question in the script)
  if (!node) {
    return usedQuestions;
  }

  // Prevent duplicates
  if (!usedQuestions.includes(node)) {
    usedQuestions.push(node);
  }

  // Base case: There is not next question mapped
  if (!hasNextQuestion(node)) {
    return usedQuestions;
  }

  // Recursivly look for Next Question
  if (!hasOptions(node.questionType)) {
    return getNextNodes(allQuestions, usedQuestions, node.nextQuestionId);
  } else {
    node.agentScriptOptions.forEach(option => {
      if (option.nextQuestionId > 0) {
        return getNextNodes(allQuestions, usedQuestions, option.nextQuestionId);
      }
    });
    return usedQuestions;
  }
};

const createNode = question => {
  return {
    id: question.id.toString(),
    type: 'question-type',
    position: position,
    data: {
      question: question,
      firstQuestion: false
    }
  };
};

const createEdge = (question, nextId = 0, label = '') => {
  const source = question.id.toString();
  const target = nextId > 0 ? nextId : question.nextQuestionId.toString();
  return {
    id: `${source}->${target}`,
    type: 'question-edge',
    source: source,
    target: target,
    label: label
  };
};

export const getInitialNodes = createAsyncThunk(
  'questions/fetchAll',
  async companyId => {
    // Validate data before
    if (isNaN(+companyId)) {
      throw Error();
    }

    // Get data from server
    const blazeoData = await BlazeoDataPromise(parseInt(companyId));

    // store all the questions (AgentScriptAnswers) available in the script
    const allQuestions = [];
    blazeoData.agentScript.questions.forEach(question => {
      question.answers.forEach(answer => {
        allQuestions.push(answer);
      });
    });

    // Recursively check which questions are used
    const usedQuestions = getNextNodes(
      allQuestions,
      [],
      blazeoData.chatGreetingAnswerId
    );

    // Get the set complement to find the unused questions
    const unusedQuestions = allQuestions.filter(
      question => !usedQuestions?.includes(question)
    );

    // Create Nodes and edges from the list of used nodes
    const dataNodes = [];
    const dataEdges = [];

    usedQuestions.forEach(question => {
      dataNodes.push(createNode(question));

      if (!hasOptions(question.questionType)) {
        if (question.nextQuestionId) {
          dataEdges.push(createEdge(question));
        }
      } else {
        const nextQuestionMapping = {};

        question.agentScriptOptions.forEach(option => {
          if (option.nextQuestionId > 0) {
            if (nextQuestionMapping[option.nextQuestionId]) {
              nextQuestionMapping[option.nextQuestionId] += '|' + option.text;
            } else {
              nextQuestionMapping[option.nextQuestionId] = option.text;
            }
          }
        });

        Object.entries(nextQuestionMapping).forEach(
          ([nextQuestionId, label]) => {
            dataEdges.push(createEdge(question, nextQuestionId, label));
          }
        );
      }
    });

    return {
      nodes: dataNodes,
      edges: dataEdges,
      firstQuestionId: blazeoData.chatGreetingAnswerId,
      allQuestions: allQuestions,
      usedQuestions: usedQuestions,
      unusedQuestions: unusedQuestions
    };
  }
);

const initialState = {
  visible: false,
  scriptBotFlow: {
    id: 0,
    companyId: '',
    chatBotId: '',
    chatBotName: '',
    isNew: false
  }
};

export const scriptBotFlowSlice = createSlice({
  name: 'scriptBotFlow',
  initialState: initialState,
  reducers: {
    setScriptBotFlow: (state, action) => {
      state.scriptBotFlow = action.payload;
    },
    show: (state, action) => {
      state.visible = true;
      state.scriptBotFlow.companyId = action.payload.companyId;
      state.scriptBotFlow.chatBotId = action.payload.chatbot_id;
      state.scriptBotFlow.chatBotName = action.payload.chatbot_name;
      state.scriptBotFlow.isNew = action.payload.isNew;
    },
    hide: state => {
      state.visible = false;
    }
  }
});

export const { show, hide, setScriptBotFlow } = scriptBotFlowSlice.actions;
export default scriptBotFlowSlice.reducer;
