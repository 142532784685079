import { Handle } from '@xyflow/react';

export function InitialNode(props) {
  return (
    <>
      <div
        className="question-type-node"
        style={{
          color: 'var(--primary)'
        }}
      >
        <div className="node-container center-text">
          <div style={{ padding: '0px 5px' }}>
            {props.data.question.text && (
              <div className="question-type-node-inner">
                {props.data.question.text}
              </div>
            )}
          </div>
        </div>
        <Handle type="source" position={props?.sourcePosition} />
      </div>
    </>
  );
}
