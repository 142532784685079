import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  visibility: false
};

export const overlaySlice = createSlice({
  name: 'overlay',
  initialState: initialState,
  reducers: {
    show: state => {
      state.visibility = true;
    },
    hide: state => {
      state.visibility = false;
    }
  }
});

export const { show, hide } = overlaySlice.actions;
export default overlaySlice.reducer;
